import config from 'site.config.json'
import { idhBrandId } from './constants'

const CDN = process.env.REACT_APP_PHNX_CDN
const apiContent = process.env.REACT_APP_PHNX_API_CONTENT
const apiSmarts = process.env.REACT_APP_PHNX_API_SMARTS
const apiUser = process.env.REACT_APP_PHNX_API_USER
const apiToken = process.env.REACT_APP_PHNX_API_AUTH
const apiUGC = process.env.REACT_APP_PHNX_API_UGC
const limaLabUrl = process.env.REACT_APP_LIMA_BUREAU
const limaLabEndpointUrl = process.env.REACT_APP_LIMA_BUREAU_ENDPOINT
const pubMedContentEndpoint = process.env.REACT_APP_PUMBED_CONTENT
const apiDougall = process.env.REACT_APP_PHNX_API_DOUGALL
/**
 * CONTENT
 */
const feedBuilderEndpoint = `${apiContent}${config.sections.siteRiver.endpoint}`
const articleUrlEndpoint = `${apiContent}/fb/search/article/url`
const getMiscEndpoint = `${apiContent}/content/misc`
/**
 * UGC
 */
// const commentsEndpoint = `${apiUGC}/ugc/comments`
const scoreEndpoint = `${apiUGC}/ugc/score`
/**
 * CDN
 */
const publishersListEndpoint = `${process.env.REACT_APP_PHNX_CDN}/assets/newsletter/dg/journal_logos/publishers.json`
const localeEndpoint = `${process.env.REACT_APP_PHNX_CDN}${config.configuration.localization.localeResource}`

/**
 * Device ID
 */
const deviceIdEndpoint = process.env.REACT_APP_DEVICE_ID

/**
 * Dougall GPT
 */
const dougallEndpoints = {
  gptPrompt: `${apiDougall}v1/gpt/prompt`,
  gptStreamPrompt: `${apiDougall}v2/gpt/prompt`,
  gptAssistantsPrompt: `${apiDougall}v2/gpt/assistants/prompt`,
  gptHistory: `${apiDougall}v2/gpt/assistants/threads`,
  gptPopularPrompts: `${apiDougall}v1/gpt/popular-prompts`,
  gptReferenceUrls: `${apiDougall}v1/gpt/reference-urls`,
  gptAnswerAction: `${apiDougall}v2/gpt/actions`,
  gptAnswerFeedback: `${apiDougall}v2/gpt/actions/feedback`,
  gptPromptTitle: `${apiDougall}v2/gpt/assistants/threads`,
  gptPromptShare: `${apiDougall}v2/gpt/prompt/share`,
  gptGetSignedUrl: `${apiDougall}v2/gpt/assistants/files/get-signed-url`,
  gptOpenAIUpload: `${apiDougall}v2/gpt/assistants/files/upload-open-ai`
}

/**
 * MISC
 */
const getFaqEndpoint = `${apiUser}v2/content/misc`

/**
 * ListIngestion Endpoints
 */
const smartsEndpoints = {
  gptToken: `${apiSmarts}/gpt/token`,
  listsUploadSignedUrl: `${apiSmarts}/list/upload/signedurl`,
  listsSignedUrl: `${apiSmarts}/signedurl`, // Only for dev. Do not use on Production
  listsUploadEndpoint: `${apiSmarts}/list/upload`,
  listsMetadataFetchAgencyEndpoint: `${apiSmarts}/list/metadata/agencies`,
  listsMetadataFetchBusinessGroupsEndpoint: `${apiSmarts}/list/metadata/business-groups`,
  listsMetadataFetchBusinessGroupsProductsEndpoint: `${apiSmarts}/list/metadata/business-groups-products`,
  listsMetadataFetchClientsEndpoint: `${apiSmarts}/list/metadata/clients`,
  listsMetadataFetchCountriesEndpoint: `${apiSmarts}/list/metadata/countries`,
  listsMetadataFetchDomainsEndpoint: `${apiSmarts}/list/metadata/domains`,
  listsMetadataFetchListIdsEndpoint: `${apiSmarts}/list/metadata/lists`,
  listsMetadataFetchMasterProjectsEndpoint: `${apiSmarts}/list/metadata/master-projects`,
  listsMetadataFetchProjectsEndpoint: `${apiSmarts}/list/metadata/projects`,
  listsMetadataEndpoint: `${apiSmarts}/list/metadata`,
  listsFetchFileHeading: `${apiSmarts}/list/headings`,
  listsSetFileHeading: `${apiSmarts}/list/headings`,
  listsSubmit: `${apiSmarts}/list/submit`,
  listsCombinationsFetchCountriesEndpoint: `${apiSmarts}/list/combination/countries`,
  listsCombinationsFetchProfessionsEndpoint: `${apiSmarts}/list/combination/professions`,
  listsCombinationsFetchSpecialtiesEndpoint: `${apiSmarts}/list/combination/specialties`,
  listsCombinationsFetchListsEndpoint: `${apiSmarts}/list/combination/lists`,
  listsCombinationsFetchProjectsEndpoint: `${apiSmarts}/list/combination/projects`,
  listsCombinationsFetchMasterProjectsEndpoint: `${apiSmarts}/list/combination/master-projects`,
  listsCombinationsFetchEngagementTypesEndpoint: `${apiSmarts}/list/combination/engagement-types`,
  listsCombinationsFetchAll: `${apiSmarts}/list/all-combinations`,
  listsCreate: `${apiSmarts}/list`,
  listsFetch: `${apiSmarts}/list`,
  listsSearch: `${apiSmarts}/list/search`,
  listsLookupStatuses: `${apiSmarts}/variation/lists-lookup-statuses`,
  listsLookupDomains: `${apiSmarts}/variation/lists-lookup-domains`,
  listsShareEmails: `${apiSmarts}/list/shared-emails`,
  listsShareGroups: `${apiSmarts}/list/shared-groups`,
  listsExportFiles: `${apiSmarts}/list/export-files`,
  listsCharts: `${apiSmarts}/list/charts`,
  listsBreakdownCharts: `${apiSmarts}/list/breakdown-charts`,
  listsChartsAnalysis: `${apiSmarts}/list/charts/analysis`,
  listsChartsUpload: `${apiSmarts}/list/charts/upload`,
  chartsShare: `${apiSmarts}/share`,
  variationExportBrandsEndpoint: `${apiSmarts}/variation/export-brands`,
  variationCountries: `${apiSmarts}/variation/countries`,
  listFilters: `${apiSmarts}/list/filters`,
  feedback: `${apiSmarts}/feedback`,
  listCoins: `${apiSmarts}/coin`,
  fetchFaq: `${apiSmarts}/content/faq`,
  advisorListEndpoint: `${apiSmarts}/bureau/search`,
  advisorProfileEndpoint: `${apiSmarts}/bureau/profile`,
  advisorScheduleEndpoint: `${apiSmarts}/bureau/schedule`,
  specialtyListEndpoint: `${apiUser}v2/reference/specialty`,
  professionListEndpoint: `${apiUser}v2/reference/profession`,
  universeSearchEndpoint: `${apiSmarts}/charts/universe`,
  universeFileEndpoint: `${apiSmarts}/charts/universe/bulk/csv`,
  universeHistory: `${apiSmarts}/charts/universe-history`,
  incomingSearchEndpoint: `${apiSmarts}/charts/incoming-counts`,
  thresholdEndpoint: `${apiSmarts}/charts/threshold`,
  variablesEndpoint: `${apiSmarts}/variable`,
  dataCachesEndpoint: `${apiSmarts}/dc`,
  chartsGenericEndpoint: `${apiSmarts}/charts/config-generic`,
  chartsSearchEndpoint: `${apiSmarts}/charts/search`,
  chartsConfigEndpoint: `${apiSmarts}/charts/config-chart`,
  chartsVariationsEndpoint: `${apiSmarts}/variation`,
  rebuildIncomingEndpoint: `${apiSmarts}/charts/incoming-counts/rebuild`,
  smartsUsers: `${apiSmarts}/users`,
  smartsRoles: `${apiSmarts}/users/roles`,
  smartsUsersSearch: `${apiSmarts}/users/search`,
  smartsUsersDownload: `${apiSmarts}/users/download`,
  smartsUsersUpload: `${apiSmarts}/users/upload`,
  smartsUsersInvalidate: `${apiSmarts}/users/invalidation`
}

const authEndpoints = {
  /**
   * USER
   */
  loginEndpoint: `${apiUser}v2/user/login`,
  loginWithEmailEndpoint: `${apiSmarts}/otp`,
  logoutEndpoint: `${apiSmarts}/logout`,
  profileEndpoint: `${apiUser}v2/user/profile/?idhBrandId=${idhBrandId}`,
  updateProfileEndpoint: `${apiUser}v2/user/profile/?idhBrandId=${idhBrandId}&tokenGen=true`,
  unsubscribeEndpoint: `${apiUser}v2/user/unsubscribe`,
  feedbackEndpoint: `${apiUser}v2/user/feedback`,
  contactUsEndpoint: `${apiUser}v2/user/contact-us`,
  userVerifyEndpoint: `${apiUser}v2/user/verify`,
  sendEmailEndpoint: `${apiUser}v2/user/share`,

  /**
   * AUTH
   */
  tokenGetEndpoint: `${apiToken}v2/auth/token`,
  tokenVerifyEndpoint: `${apiToken}smarts/token/verify`,
  tokenRefreshEndpoint: `${apiToken}v2/auth/token/refresh`,

  /**
   * LIMA LAB
   */
  limaLabEndpoint: `${limaLabEndpointUrl}v1/auth/token/LimaB`
}

export {
  CDN,
  publishersListEndpoint,
  getFaqEndpoint,
  smartsEndpoints,
  authEndpoints,
  deviceIdEndpoint,
  dougallEndpoints,
  feedBuilderEndpoint,
  articleUrlEndpoint,
  getMiscEndpoint,
  scoreEndpoint,
  limaLabUrl,
  pubMedContentEndpoint,
  localeEndpoint
}
