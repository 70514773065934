import { all, call, put, select, take, takeEvery } from 'redux-saga/effects'
// import i18next from 'i18next'
// import moment from 'moment'

import { actions as notificationActions } from 'modules/notification/NotificationReducer'
import { actions as historyActions } from './HistoryReducer'
import { actions as chatActions } from 'modules/dougall/chat/ChatReducer'
import { actions as trackingActions } from 'modules/dougall/tracking/TrackingReducer'
import { getRawHistory } from './HistorySelector'
import { getIsAuth, getIsLimited } from 'modules/auth/AuthSelector'
import { actions as deviceIdActions } from 'modules/deviceId/DeviceIdReducer'

/**
 * Request history
 * @param services
 * @param payload
 */
function* historyRequest(services) {
  const DougallService = services('DougallService')
  const CookieService = services('CookieService')
  try {
    const isAuth = yield select(getIsAuth())
    const isLimited = yield select(getIsLimited())
    // Get device
    let deviceId
    if (!isAuth) {
      deviceId = yield call(
        [CookieService, 'getFromCache'],
        'psl_device_id',
        ''
      )
    } else {
      deviceId = yield call(
        [CookieService, 'getFromCache'],
        'psl_device_id',
        'DeviceID not available'
      )
    }

    // Exit if user is anon & deviceId is not ready
    if (!isAuth && !deviceId) {
      return
    }
    // Call endpoint
    const { history, raw: rawHistory } = yield call(
      [DougallService, 'historyRequest'],
      isAuth && !isLimited ? deviceId : `${deviceId}+${Date.now()}`
    )
    yield put(historyActions.requestHistorySuccess({ history, rawHistory }))
  } catch (e) {
    console.error(e)
    yield put(historyActions.requestHistoryFail())
    // yield put(
    //   notificationActions.notificationEnqueue({
    //     message: i18next.t('historyFail'),
    //     type: 'error'
    //   })
    // )
  }
}

/**
 * Request prompt history
 * @param services
 * @param payload
 */
function* promptHistoryRequest(services, { payload }) {
  const DougallService = services('DougallService')
  const CookieService = services('CookieService')

  // const date = moment().format('DD/MM/YYYY')
  const { promptId = undefined, pId = undefined, source = undefined } = payload
  try {
    const isAuth = yield select(getIsAuth())
    const isLimited = yield select(getIsLimited())
    // Get device
    let deviceId
    if (!isAuth) {
      deviceId = yield call(
        [CookieService, 'getFromCache'],
        'psl_device_id',
        ''
      )
    } else {
      deviceId = yield call(
        [CookieService, 'getFromCache'],
        'psl_device_id',
        'DeviceID not available'
      )
    }

    // Exit if user is anon & deviceId is not ready
    if (!isAuth && !deviceId) {
      yield take(deviceIdActions.deviceIdReady.type)
    }

    yield put(chatActions.resetMessages())
    yield put(chatActions.resetLinkedPromptId())
    yield put(chatActions.promptRequestStart())

    // Call endpoint
    const { messages, tags, lastAnswerTags } = yield call(
      [DougallService, 'promptHistoryRequest'],
      isAuth && !isLimited ? deviceId : `${deviceId}+${Date.now()}`,
      promptId,
      pId,
      pId ? true : undefined
    )
    // Add date chunk divider
    // if (date && !messages.find(m => m.date === date && m.isDivider)) {
    //   yield put(
    //     chatActions.addMessage({
    //       date,
    //       isDivider: true
    //     })
    //   )
    // }
    yield put(chatActions.setLinkedPromptId(promptId))
    yield put(chatActions.addMessages(messages))
    yield call([CookieService, 'saveToCache'], 'tags', lastAnswerTags)
    yield put(chatActions.addTags({ tags }))

    yield put(chatActions.promptSuccess())
    yield put(chatActions.showInterests())

    //Tracking if user comes from a shared prompt link
    if (source === 'emailshare') {
      for (let i = 0; i < messages.length; i++) {
        const { answerId, promptId } = messages[i]
        if (answerId && promptId) {
          yield put(
            trackingActions.trackClickAction({
              title: 'Email/Click/view-answer',
              userData: {
                answerId,
                promptId
              }
            })
          )
          break
        }
      }
    }
  } catch (e) {
    console.error(e)
    yield put(
      notificationActions.notificationEnqueue({
        message: 'There was a problem with the request.',
        type: 'error'
      })
    )
    yield put(chatActions.promptFail())
  } finally {
    yield put(chatActions.setUrlRequested(true))
  }
}

/**
 * Request history
 * @param services
 * @param payload
 */
function* deleteHistoryPromptRequest(services, { payload }) {
  const DougallService = services('DougallService')
  const CookieService = services('CookieService')
  const DougallParser = services('DougallParser')
  const { id = undefined, t } = payload
  try {
    const isAuth = yield select(getIsAuth())
    const isLimited = yield select(getIsLimited())
    // Get device, we can assume the deviceId is ready for anon users since the history will load only when device id is ready
    let deviceId
    if (!isAuth) {
      deviceId = yield call(
        [CookieService, 'getFromCache'],
        'psl_device_id',
        ''
      )
    } else {
      deviceId = yield call(
        [CookieService, 'getFromCache'],
        'psl_device_id',
        'DeviceID not available'
      )
    }
    // Call endpoint
    yield call(
      [DougallService, 'deleteHistoryConversation'],
      id,
      isAuth && !isLimited ? deviceId : `${deviceId}+${Date.now()}`
    )

    yield put(historyActions.deleteHistorySuccess())
    yield put(historyActions.setClearAllOpen({ open: false }))
    yield put(
      notificationActions.notificationEnqueue({
        message: t('historyDeletePromptSuccess'),
        type: 'success'
      })
    )
    // Update History list without
    let newHistory = [],
      rawHistory = []
    // If single id, remove entry from history list
    if (id) {
      rawHistory = yield select(getRawHistory())
      const indexToRemove = rawHistory.findIndex(h => h.id === id)
      if (indexToRemove !== -1) {
        newHistory = [
          ...rawHistory.slice(0, indexToRemove),
          ...rawHistory.slice(indexToRemove + 1)
        ]
      } else {
        newHistory = rawHistory
      }
    }
    const parsedHistory = yield call([DougallParser, 'historyParser'], {
      threads: newHistory
    })
    // Update history state
    yield put(
      historyActions.requestHistorySuccess({
        history: parsedHistory,
        rawHistory: newHistory
      })
    )
  } catch (e) {
    console.error(e)
    yield put(historyActions.deleteHistoryFail())
    // yield put(
    //   notificationActions.notificationEnqueue({
    //     message: i18next.t('historyFail'),
    //     type: 'error'
    //   })
    // )
  }
}

/**
 * Request History Title Change
 * @param {*} services
 * @param {*} payload
 */
function* changeTitleRequest(services, { payload }) {
  const DougallService = services('DougallService')
  const CookieService = services('CookieService')
  const { id, title } = payload

  try {
    const isAuth = yield select(getIsAuth())

    let deviceId
    if (!isAuth) {
      deviceId = yield call(
        [CookieService, 'getFromCache'],
        'psl_device_id',
        ''
      )
    } else {
      deviceId = yield call(
        [CookieService, 'getFromCache'],
        'psl_device_id',
        'DeviceID not available'
      )
    }

    yield call([DougallService, 'titleChangeRequest'], {
      deviceId,
      applicationLinkedPromptId: id,
      title
    })
  } catch (e) {
    console.log(e)
  }
}

export default function* watchHistoryRequest(services) {
  yield all([takeEvery('HISTORY_REQUEST', historyRequest, services)])
  yield all([takeEvery('HISTORY_DELETE', deleteHistoryPromptRequest, services)])
  yield all([
    takeEvery('PROMPT_HISTORY_REQUEST', promptHistoryRequest, services)
  ])
  yield all([
    takeEvery('HISTORY_REQUEST_TITLE_CHANGE', changeTitleRequest, services)
  ])
}
