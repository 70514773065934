import { useRef } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import TextareaAutosize from 'react-textarea-autosize'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames/bind'

import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { Tooltip, tooltipClasses } from '@mui/material'
import { styled } from '@mui/material/styles'
import { AttachFile, Close, InsertDriveFile } from '@mui/icons-material'

import { actions as notificationActions } from 'modules/notification/NotificationReducer'

import Typography from 'components/ui/Typography/Typography'
import { BroomIcon, SendIcon } from 'static/icons'
import { isEmpty } from 'utils'
import styles from './PromptBar.module.sass'

import siteConfig from 'site.config.json'

const {
  configuration: { gpt }
} = siteConfig

const cx = classNames.bind(styles)

/**
 * Send Button
 * forwardProps: customStyles
 */
const SendButton = styled(IconButton)(() => ({
  backgroundColor: '#c50201 !important',
  border: '0px',
  borderRadius: '33px',
  height: '32px',
  width: '32px',
  padding: '0',
  marginRight: '13px'
}))

/**
 * New Topic Button
 * forwardProps: customStyles
 */
const NewTopicButton = styled(IconButton)(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  backgroundColor: '#293056 !important',
  border: '0px',
  borderRadius: '33px',
  height: '62px',
  width: '62px',
  marginRight: '5px',
  maxWidth: '62px',
  maxHeight: '62px',
  padding: '6px 15px 12px',
  p: {
    lineHeight: '1',
    whiteSpace: 'normal',
    pointerEvents: 'none',
    margin: '0',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: '12px',
    color: '#fff',
    fontFamily: 'Inter'
  }
}))

/**
 * File Input Visually Hidden
 */
const HiddenInput = styled('input', {
  name: 'PromptBar',
  label: 'promptBarHiddenInput'
})({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
})

/**
 * File Display when existing
 */
const FileDisplay = styled(
  ({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ),
  {
    name: 'PromptBar',
    label: 'promptBarFileDisplay'
  }
)({
  [`& .${tooltipClasses.tooltip}`]: {
    display: 'flex',
    padding: '5px 10px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    '& svg': {
      color: '#029be5'
    },
    '& p': {
      fontFamily: 'Inter',
      color: '#444',
      marginLeft: '4px',
      paddingTop: '0px'
    },
    '& button': {
      color: '#444',
      marginLeft: '5px',
      height: '25px',
      width: '25px',
      '& svg': {
        color: '#444',
        height: '20px',
        width: '20px'
      }
    }
  }
})

const PromptBar = ({
  name = 'prompt-bar',
  placeholder = '',
  file,
  newTopic = true,
  redShadow = false,
  isSubmitting = false,
  onNewTopicClick = () => {},
  onFileChange = () => {},
  onFileCancel = () => {}
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { values, setFieldValue, submitForm } = useFormikContext()
  const inputRef = useRef(null)

  const { promptWordLimit, fileUpload } = gpt || {}
  const { accept = {} } = fileUpload || {}
  const acceptedFiles = Object.keys(accept).join(',')

  const { name: fileName = '' } = file || {}

  const handleChange = e => {
    // Limit number of words to 500, see #DOUGGPT-283
    if (e.target.value.trim().split(' ').length <= promptWordLimit) {
      setFieldValue(name, e.target.value)
    } else {
      return dispatch(
        notificationActions.notificationEnqueue({
          message:
            'Prompt text exceeded limit of ' + promptWordLimit + ' words.',
          duration: 5000
        })
      )
    }
  }
  // Detect Enter Key to submit
  const onEnterPress = e => {
    if (e.keyCode === 13) {
      e.preventDefault()
      submitForm()
    }
  }

  return (
    <div className={styles.container}>
      {newTopic && (
        <NewTopicButton
          onClick={() => {
            onNewTopicClick()
          }}
          data-testid="NewTopicButton"
          disabled={isSubmitting}
          className={isSubmitting ? 'loading' : ''}
        >
          <Box sx={{ height: '20px', display: 'flex', alignItems: 'center' }}>
            <BroomIcon
              sx={{
                height: '16.5px',
                width: '12.9px',
                margin: '0'
              }}
            />
          </Box>
          <Typography>{t('newTopic')}</Typography>
        </NewTopicButton>
      )}
      <FileDisplay
        open={!isEmpty(fileName)}
        title={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <InsertDriveFile />
            <Typography>{fileName}</Typography>
            <IconButton onClick={onFileCancel}>
              <Close />
            </IconButton>
          </Box>
        }
        placement="top-start"
      >
        <div className={cx(styles.promptContainer, { redShadow })}>
          <div className={styles.prompt}>
            <IconButton component="label">
              <AttachFile />
              <HiddenInput
                type="file"
                accept={acceptedFiles}
                onChange={onFileChange}
              />
            </IconButton>
            <TextareaAutosize
              id={name}
              name={name}
              ref={inputRef}
              label=""
              value={values[name]}
              placeholder={placeholder}
              className={styles.input}
              onChange={handleChange}
              onKeyDown={onEnterPress}
              maxRows={6}
            />
            <SendButton type="submit">
              <SendIcon
                sx={{
                  height: '20px',
                  width: '20px',
                  margin: '0'
                }}
              />
            </SendButton>
          </div>
        </div>
      </FileDisplay>
    </div>
  )
}

PromptBar.propTypes = {
  /**
   * Name identifier for a form input
   */
  name: PropTypes.string,
  /**
   * Prompt Bar input placeholder
   */
  placeholder: PropTypes.string,
  /**
   * New Topic Button show bool
   */
  newTopic: PropTypes.bool,
  /**
   * Shadow style color red
   */
  redShadow: PropTypes.bool,
  /**
   * Disables new topic button while is fetching
   */
  isSubmitting: PropTypes.bool,
  /**
   * Function to execute on New Topic button click
   */
  onNewTopicClick: PropTypes.func,
  /**
   * File object to show display
   */
  file: PropTypes.object,
  /**
   * Function to excecute on File Change
   */
  onFileChange: PropTypes.func,
  /**
   * Function to execute on File Cancel
   */
  onFileCancel: PropTypes.func
}

export default PromptBar
