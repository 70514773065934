import { isEmpty } from 'utils'
import {
  getMiscEndpoint,
  dougallEndpoints,
  piwikSiteId,
  phnxSiteID
} from 'constants'

const DougallService = (DougallML, DougallController, DougallParser) => ({
  /**
   * Chat prompt request
   * @param { string } prompt
   * @returns response
   */
  async promptRequest(prompt) {
    const response = await DougallML.fetch(
      dougallEndpoints.gptPrompt,
      'POST',
      prompt
    )

    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },
  /**
   * Chat Prompt Request as a Stream
   * @param { object } payload
   * @returns response object
   */
  async promptStreamRequest(payload) {
    const {
      applicationLinkedPromptId: linkedPromptId,
      hcpPrompt,
      threadId
    } = payload

    const response = await DougallML.sseFetch(
      process.env.NODE_ENV !== 'development'
        ? dougallEndpoints.gptAssistantsPrompt
        : 'http://localhost:3005/v2/gpt/assistants/prompt',
      'POST',
      {
        ...payload,
        siteId: parseInt(phnxSiteID),
        source: piwikSiteId,
        type: 'regular'
      }
    )

    if (response) {
      DougallController.handleStreamAnswer({
        response,
        linkedPromptId,
        hcpPrompt,
        threadId
      })
    }

    return response
  },
  async historyRequest(deviceId = undefined) {
    const response = await DougallML.fetch(
      dougallEndpoints.gptHistory,
      'POST',
      {
        deviceId,
        source: parseInt(piwikSiteId),
        siteId: parseInt(phnxSiteID)
      }
    )

    if (!isEmpty(response?.data)) {
      return {
        raw: response.data.responseData?.threads || [],
        history: DougallParser.historyParser(response.data.responseData)
      }
    }
    return {}
  },
  /**
   * Fetch prompt thread
   * @param {*} deviceId
   * @param {*} promptId parent/linked promptId
   * @returns parsed response
   */
  async promptHistoryRequest(
    deviceId = undefined,
    threadId = undefined,
    sharedPrompt = undefined
  ) {
    const response = await DougallML.fetch(
      process.env.NODE_ENV !== 'development'
        ? dougallEndpoints.gptHistory + '/' + threadId
        : 'http://localhost:5000/v2/gpt/assistants/threads/' + threadId,
      'POST',
      {
        deviceId,
        applicationPromptId: threadId,
        sharedPrompt,
        source: parseInt(piwikSiteId),
        siteId: parseInt(phnxSiteID)
      }
    )

    if (!isEmpty(response?.data)) {
      return DougallParser.promptHistoryParser(
        response.data.responseData.messages
      )
    }
    return {}
  },
  /**
   * Delete single prompt in history
   * @param {*} promptId
   * @param {*} deviceId
   * @returns
   */
  async deleteHistoryPrompt(promptId, deviceId = undefined) {
    const response = await DougallML.fetch(dougallEndpoints.gptHistory, 'PUT', {
      applicationPromptId: promptId,
      deviceId,
      source: parseInt(piwikSiteId),
      siteId: parseInt(phnxSiteID)
    })

    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },
  /**
   * Delete single conversation in history
   * @param {*} promptId
   * @param {*} deviceId
   * @returns
   */
  async deleteHistoryConversation(promptId, deviceId = undefined) {
    let url = dougallEndpoints.gptHistory

    // Append promptId to the URL only if it's defined, otherwise delete all
    if (promptId) {
      url += `/${promptId}`
    }

    const requestBody = {
      ...(promptId && {
        applicationLinkedPromptId: promptId,
        threadId: promptId
      }), // only if promptId is defined
      deviceId,
      source: parseInt(piwikSiteId),
      siteId: parseInt(phnxSiteID)
    }

    const response = await DougallML.fetch(url, 'DELETE', requestBody)

    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },
  /**
   * FAQ request
   * @param {*} payload
   * @returns
   */
  async faqRequest(payload) {
    const response = await DougallML.fetch(
      process.env.NODE_ENV !== 'development'
        ? getMiscEndpoint
        : getMiscEndpoint + '/faq',
      'POST',
      { ...payload }
    )

    if (!isEmpty(response?.data)) {
      return response.data
    }
    return {}
  },
  /**
   * Popular Prompts get request
   * @param {*} promptId
   * @returns
   */
  async popularPromptsRequest(payload) {
    const response = await DougallML.fetch(
      process.env.NODE_ENV !== 'development'
        ? getMiscEndpoint
        : getMiscEndpoint + '/popularPrompt',
      'POST',
      { ...payload }
    )

    if (!isEmpty(response?.data)) {
      // return response.data.responseData
      return response.data
    }
    return {}
  },
  /**
   * Dougall MD
   * request for extra tag buttons in feed
   */
  async referenceUrlsRequest(payload) {
    const response = await DougallML.fetch(
      dougallEndpoints.gptReferenceUrls,
      'POST',
      { ...payload }
    )

    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },
  /**
   * Answer Action Request
   * @param {} payload
   * @returns response
   */
  async answerActionRequest(payload) {
    const response = await DougallML.fetch(
      dougallEndpoints.gptAnswerAction,
      'POST',
      payload
    )

    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },
  /**
   * Answer Feedback Submit
   * @param {} payload
   * @returns response
   */
  async answerFeedbackSubmit(payload) {
    const response = await DougallML.fetch(
      dougallEndpoints.gptAnswerFeedback,
      'POST',
      DougallParser.feedbackRequestParser(payload)
    )

    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },
  /**
   * Landing Misc request
   * @param {*} payload
   * @returns
   */
  async landingRequest(payload) {
    const response = await DougallML.fetch(getMiscEndpoint, 'POST', {
      ...payload
    })

    if (!isEmpty(response?.data)) {
      return response.data
    }
    return {}
  },
  /**
   * History Title Change Request
   * @param {*} payload
   * @returns
   */
  async titleChangeRequest(payload) {
    const threadId = payload.applicationLinkedPromptId
    const response = await DougallML.fetch(
      `${dougallEndpoints.gptPromptTitle}/${threadId}`,
      'PUT',
      {
        ...payload,
        source: parseInt(piwikSiteId),
        siteId: parseInt(phnxSiteID)
      }
    )

    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },
  /**
   * Prompt Share Request
   * @param {*} payload
   * @returns
   */
  async promptShareRequest(payload) {
    const response = await DougallML.fetch(
      dougallEndpoints.gptPromptShare,
      'POST',
      DougallParser.shareRequestParser(payload)
    )

    if (!isEmpty(response?.data)) {
      return response.data
    }
    return {}
  },
  /**
   * File Request Signed Url
   * @param {*} payload
   * @returns
   */
  async fileSignedUrlRequest(payload) {
    const response = await DougallML.fetch(
      dougallEndpoints.gptGetSignedUrl,
      'POST',
      payload,
      {},
      true
    )

    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  },
  /**
   * File Signed Url Upload
   * @param {*} url
   * @param {*} fields
   * @param {*} file
   */
  async fileSignedUrlUpload(url, fields, file) {
    const formData = DougallParser.fileFormDataParser({ fields, file })

    await DougallML.fileUpload(url, formData)
  },
  /**
   * File Open AI Upload
   * @param {*} payload
   * @returns
   */
  async fileOpenAIUpload(payload) {
    const response = await DougallML.fetch(
      dougallEndpoints.gptOpenAIUpload,
      'POST',
      payload,
      {},
      true
    )

    if (!isEmpty(response?.data)) {
      return response.data.responseData
    }
    return {}
  }
})

export default DougallService
