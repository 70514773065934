import '@testing-library/jest-dom'
import { dougallEndpoints } from 'constants'
import DougallService from './DougallService'
import { isEmpty } from 'utils'

jest.mock('constants', () => ({
  dougallEndpoints: {
    gptPrompt: 'mockedEndpoint/gptPrompt',
    gptAssistantsPrompt: 'mockedEndpoint/gptAssistantsPrompt',
    gptHistory: 'mockedEndpoint/gptHistory',
    gptPromptTitle: 'mockedEndpoint/gptPromptTitle',
    gptPromptShare: 'mockedEndpoint/gptPromptShare',
    gptGetSignedUrl: 'mockedEndpoint/gptGetSignedUrl',
    gptReferenceUrls: 'mockedEndpoint/gptReferenceUrls',
    gptAnswerAction: 'mockedEndpoint/gptAnswerAction',
    gptAnswerFeedback: 'mockedEndpoint/gptAnswerFeedback',
    gptOpenAIUpload: 'mockedEndpoint/gptOpenAIUpload'
  },
  getMiscEndpoint: 'mockedMiscEndpoint',
  phnxSiteID: '12345',
  piwikSiteId: '67890'
}))

jest.mock('utils', () => ({
  isEmpty: jest.fn()
}))

describe('DougallService', () => {
  const mockDougallML = {
    fetch: jest.fn(),
    sseFetch: jest.fn(),
    fileUpload: jest.fn()
  }
  const mockDougallController = {
    handleStreamAnswer: jest.fn()
  }
  const mockDougallParser = {
    historyParser: jest.fn(),
    promptHistoryParser: jest.fn(),
    feedbackRequestParser: jest.fn(),
    shareRequestParser: jest.fn(),
    fileFormDataParser: jest.fn()
  }

  const service = DougallService(
    mockDougallML,
    mockDougallController,
    mockDougallParser
  )

  afterEach(() => {
    jest.clearAllMocks()
  })

  describe('promptRequest', () => {
    it('should return response data when the response is not empty', async () => {
      const prompt = 'Test prompt'
      const mockResponse = {
        data: { responseData: 'Expected response' }
      }

      mockDougallML.fetch.mockResolvedValueOnce(mockResponse)

      const result = await service.promptRequest(prompt)

      expect(mockDougallML.fetch).toHaveBeenCalledWith(
        dougallEndpoints.gptPrompt,
        'POST',
        prompt
      )

      expect(result).toEqual('Expected response')
    })

    it('should return an empty object when the response is empty', async () => {
      const prompt = 'Test prompt'
      const mockResponse = { data: null }
      isEmpty.mockReturnValue(true)

      mockDougallML.fetch.mockResolvedValueOnce(mockResponse)

      const result = await service.promptRequest(prompt)

      expect(mockDougallML.fetch).toHaveBeenCalledWith(
        dougallEndpoints.gptPrompt,
        'POST',
        prompt
      )

      expect(result).toEqual({})
    })

    it('should handle a stream response for promptStreamRequest', async () => {
      const payload = {
        applicationLinkedPromptId: 'linkedId',
        hcpPrompt: 'hcpPrompt',
        threadId: 'threadId'
      }
      const mockResponse = { stream: true }
      mockDougallML.sseFetch.mockResolvedValueOnce(mockResponse)

      const result = await service.promptStreamRequest(payload)

      expect(mockDougallML.sseFetch).toHaveBeenCalledWith(
        'mockedEndpoint/gptAssistantsPrompt',
        'POST',
        expect.objectContaining({
          ...payload,
          type: 'regular',
          siteId: parseInt('12345')
        })
      )
      expect(mockDougallController.handleStreamAnswer).toHaveBeenCalledWith({
        response: mockResponse,
        linkedPromptId: 'linkedId',
        hcpPrompt: 'hcpPrompt',
        threadId: 'threadId'
      })
      expect(result).toEqual(mockResponse)
    })

    it('should fetch and parse history for historyRequest', async () => {
      const mockResponse = {
        data: {
          responseData: {
            threads: ['thread1', 'thread2']
          }
        }
      }
      mockDougallML.fetch.mockResolvedValueOnce(mockResponse)
      mockDougallParser.historyParser.mockReturnValue([
        'parsedThread1',
        'parsedThread2'
      ])
      isEmpty.mockReturnValue(false)

      const result = await service.historyRequest('device123')

      expect(mockDougallML.fetch).toHaveBeenCalledWith(
        'mockedEndpoint/gptHistory',
        'POST',
        expect.objectContaining({
          deviceId: 'device123',
          source: parseInt('67890'),
          siteId: parseInt('12345')
        })
      )
      expect(mockDougallParser.historyParser).toHaveBeenCalledWith(
        mockResponse.data.responseData
      )
      expect(result).toEqual({
        raw: ['thread1', 'thread2'],
        history: ['parsedThread1', 'parsedThread2']
      })
    })

    it('should call fetch and return response data for deleteHistoryPrompt', async () => {
      const mockResponse = { data: { responseData: 'deleted prompt data' } }
      mockDougallML.fetch.mockResolvedValueOnce(mockResponse)
      isEmpty.mockReturnValue(false)

      const result = await service.deleteHistoryPrompt(
        'promptId123',
        'deviceId123'
      )

      expect(mockDougallML.fetch).toHaveBeenCalledWith(
        'mockedEndpoint/gptHistory',
        'PUT',
        expect.objectContaining({
          applicationPromptId: 'promptId123',
          deviceId: 'deviceId123',
          source: parseInt('67890'),
          siteId: parseInt('12345')
        })
      )
      expect(result).toEqual('deleted prompt data')
    })

    it('should fetch and return signed URL data for fileSignedUrlRequest', async () => {
      const payload = { fileName: 'testFile.txt', fileType: 'text/plain' }
      const mockResponse = { data: { responseData: 'signedUrlData' } }

      mockDougallML.fetch.mockResolvedValueOnce(mockResponse)
      isEmpty.mockReturnValue(false)

      const result = await service.fileSignedUrlRequest(payload)

      expect(mockDougallML.fetch).toHaveBeenCalledWith(
        'mockedEndpoint/gptGetSignedUrl',
        'POST',
        payload,
        {},
        true
      )
      expect(isEmpty).toHaveBeenCalledWith(mockResponse.data)
      expect(result).toEqual('signedUrlData')
    })

    it('should upload file using signed URL for fileSignedUrlUpload', async () => {
      const url = 'https://signedurl.mock/upload'
      const fields = { key: 'value' }
      const file = new Blob(['test file content'], { type: 'text/plain' })
      const formData = new FormData()

      mockDougallParser.fileFormDataParser.mockReturnValueOnce(formData)

      await service.fileSignedUrlUpload(url, fields, file)

      expect(mockDougallParser.fileFormDataParser).toHaveBeenCalledWith({
        fields,
        file
      })
      expect(mockDougallML.fileUpload).toHaveBeenCalledWith(url, formData)
    })

    it('should fetch and return OpenAI upload response for fileOpenAIUpload', async () => {
      const payload = { fileName: 'testFile.txt', fileType: 'text/plain' }
      const mockResponse = { data: { responseData: 'openAIUploadData' } }

      mockDougallML.fetch.mockResolvedValueOnce(mockResponse)
      isEmpty.mockReturnValue(false)

      const result = await service.fileOpenAIUpload(payload)

      expect(mockDougallML.fetch).toHaveBeenCalledWith(
        'mockedEndpoint/gptOpenAIUpload',
        'POST',
        payload,
        {},
        true
      )
      expect(isEmpty).toHaveBeenCalledWith(mockResponse.data)
      expect(result).toEqual('openAIUploadData')
    })

    it('should fetch and return parsed prompt history for promptHistoryRequest', async () => {
      const deviceId = 'testDeviceId'
      const threadId = 'testThreadId'
      const sharedPrompt = 'testSharedPrompt'
      const source = 67890
      const siteId = 12345

      const mockResponse = {
        data: { responseData: { messages: ['message1', 'message2'] } }
      }

      mockDougallML.fetch.mockResolvedValueOnce(mockResponse)
      isEmpty.mockReturnValue(false)
      mockDougallParser.promptHistoryParser.mockReturnValueOnce([
        'parsedMessage1',
        'parsedMessage2'
      ])

      const result = await service.promptHistoryRequest(
        deviceId,
        threadId,
        sharedPrompt
      )

      expect(mockDougallML.fetch).toHaveBeenCalledWith(
        'mockedEndpoint/gptHistory/' + threadId,
        'POST',
        {
          deviceId,
          applicationPromptId: threadId,
          sharedPrompt,
          source: source,
          siteId: siteId
        }
      )
      expect(isEmpty).toHaveBeenCalledWith(mockResponse.data)
      expect(mockDougallParser.promptHistoryParser).toHaveBeenCalledWith(
        mockResponse.data.responseData.messages
      )
      expect(result).toEqual(['parsedMessage1', 'parsedMessage2'])
    })

    it('should send delete request and return response data for deleteHistoryConversation', async () => {
      const promptId = 'testPromptId'
      const deviceId = 'testDeviceId'
      const mockResponse = { data: { responseData: 'deletedConversationData' } }
      const source = 67890
      const siteId = 12345

      mockDougallML.fetch.mockResolvedValueOnce(mockResponse)
      isEmpty.mockReturnValue(false)

      const result = await service.deleteHistoryConversation(promptId, deviceId)

      expect(mockDougallML.fetch).toHaveBeenCalledWith(
        'mockedEndpoint/gptHistory/testPromptId',
        'DELETE',
        {
          applicationLinkedPromptId: promptId,
          threadId: promptId,
          deviceId,
          source: source,
          siteId: siteId
        }
      )
      expect(isEmpty).toHaveBeenCalledWith(mockResponse.data)
      expect(result).toEqual('deletedConversationData')
    })

    it('should fetch and return FAQ response data for faqRequest', async () => {
      const payload = { query: 'testQuery' }
      const mockResponse = { data: { responseData: 'faqData' } }

      const getMiscEndpoint = 'mockedMiscEndpoint'

      mockDougallML.fetch.mockResolvedValueOnce(mockResponse)
      isEmpty.mockReturnValue(false)

      const result = await service.faqRequest(payload)

      expect(mockDougallML.fetch).toHaveBeenCalledWith(
        getMiscEndpoint,
        'POST',
        payload
      )
      expect(isEmpty).toHaveBeenCalledWith(mockResponse.data)
      expect(result).toEqual(mockResponse.data)
    })

    it('should fetch and return popular prompts data for popularPromptsRequest', async () => {
      const payload = { query: 'testQuery' }
      const mockResponse = { data: { responseData: 'popularPromptsData' } }

      const getMiscEndpoint =
        process.env.NODE_ENV !== 'development'
          ? 'mockedMiscEndpoint'
          : 'mockedMiscEndpoint/popularPrompt'

      mockDougallML.fetch.mockResolvedValueOnce(mockResponse)
      isEmpty.mockReturnValue(false)

      const result = await service.popularPromptsRequest(payload)

      expect(mockDougallML.fetch).toHaveBeenCalledWith(
        getMiscEndpoint,
        'POST',
        payload
      )
      expect(isEmpty).toHaveBeenCalledWith(mockResponse.data)
      expect(result).toEqual(mockResponse.data)
    })

    it('should fetch and return reference URLs data for referenceUrlsRequest', async () => {
      const payload = { query: 'testQuery' }
      const mockResponse = { data: { responseData: 'referenceUrlsData' } }

      mockDougallML.fetch.mockResolvedValueOnce(mockResponse)
      isEmpty.mockReturnValue(false)

      const result = await service.referenceUrlsRequest(payload)

      expect(mockDougallML.fetch).toHaveBeenCalledWith(
        dougallEndpoints.gptReferenceUrls,
        'POST',
        payload
      )
      expect(isEmpty).toHaveBeenCalledWith(mockResponse.data)

      expect(result).toEqual(mockResponse.data.responseData)
    })

    it('should fetch and return answer action data for answerActionRequest', async () => {
      const payload = { query: 'testQuery' }
      const mockResponse = { data: { responseData: 'answerActionData' } }

      mockDougallML.fetch.mockResolvedValueOnce(mockResponse)
      isEmpty.mockReturnValue(false)

      const result = await service.answerActionRequest(payload)

      expect(mockDougallML.fetch).toHaveBeenCalledWith(
        dougallEndpoints.gptAnswerAction,
        'POST',
        payload
      )
      expect(isEmpty).toHaveBeenCalledWith(mockResponse.data)

      expect(result).toEqual(mockResponse.data.responseData)
    })

    it('should fetch and return title change data', async () => {
      const payload = {
        title: 'New Title',
        promptId: '1234',
        applicationLinkedPromptId: '1234'
      }
      const mockResponse = { data: { responseData: 'titleChangeData' } }

      mockDougallML.fetch.mockResolvedValueOnce(mockResponse)

      const result = await service.titleChangeRequest(payload)

      expect(mockDougallML.fetch).toHaveBeenCalledWith(
        'mockedEndpoint/gptPromptTitle/1234',
        'PUT',
        {
          ...payload,
          source: expect.any(Number),
          siteId: expect.any(Number)
        }
      )

      expect(result).toEqual('titleChangeData')
    })
  })
})
